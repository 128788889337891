<template>
    <div>

    </div>
</template>

<script>
import GameService from '../../services/GameService';

export default {
    name: 'GameSG',
    props: {
        username: String
    },
    data () {
        return {
            user_token: localStorage.getItem('user'),
        }
    },
    methods: {
        async login_member() {
            const { url } = await GameService.login_to_sexy_lobby(this.username)
            if (url) {
                window.location.href = url
            } else {
                this.$router.push('/');
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.login_member()
        },100)
    }
}
</script>